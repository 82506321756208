<!--
 * @Description: 诱导屏发布管理 诱导发布管理 三级诱导屏 thirdScreen
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-11-11 12:00:27
 * @LastEditors: zhoucheng
-->
<template>
  <el-row class="mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="100px"
                          label="诱导屏编号：">
              <el-input placeholder="请输入"
                        v-model="searchForm.plateNumber">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="100px"
                          label="诱导屏名称：">
              <el-input placeholder="请输入"
                        v-model="searchForm.plateNumber">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>

          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>三级诱导屏配置列表</span>
        <span class="topButton">
          <el-button type="primary">屏幕亮度</el-button>
          <el-button type="primary"
                     style="margin-left:17px">开关屏配置</el-button>
          <el-button type="primary"
                     style="margin-left:17px">刷新间隔</el-button>
          <el-button type="primary"
                     style="margin-left:17px">告警百分比</el-button>
          <el-button type="primary"
                     style="margin-left:17px">校时</el-button>
          <el-button type="primary"
                     style="margin-left:17px">内容配置</el-button>
        </span>
      </el-row>
      <el-row class="tableContent">
        <el-col class="box"
                v-for="(item,index) in 10"
                :key="index">
          <el-row class="lineOne">
            <el-row class="lineOneLeft">
              <el-row class="lineOneLeftItem"></el-row>
            </el-row>
            <el-row class="lineOneRight">
              <el-row class="lineOneRight-one"> SRLYDP000001</el-row>
              <el-row class="lineOneRight-two"> 圣人路车行道三级诱导屏</el-row>
            </el-row>
          </el-row>

          <el-row class="lineTwo">
            <el-row class="lineTwoLeft">
              <el-row class="lineTwoLeft-left">
                <el-row class="lineTwoLeft-leftOne">屏幕亮度</el-row>
                <el-row class="lineTwoLeft-leftTwo">自动</el-row>
              </el-row>
              <el-row class="lineTwoLeft-right"></el-row>
            </el-row>
            <el-row class="lineTwoRight">
              <el-row class="lineTwoRight-left">
                <el-row class="lineTwoRight-leftOne">开关屏配置</el-row>
                <el-row class="lineTwoRight-leftTwo">自动</el-row>
              </el-row>
              <el-row class="lineTwoRight-right"></el-row>
            </el-row>
          </el-row>

          <el-row class="lineThree">
            <el-row class="lineThreeLeft">
              <el-row class="lineThreeLeft-left">
                <el-row class="lineThreeLeft-leftOne">刷新间隔</el-row>
                <el-row class="lineThreeLeft-leftTwo">60S</el-row>
              </el-row>
              <el-row class="lineThreeLeft-right"></el-row>
            </el-row>
            <el-row class="lineThreeRight">
              <el-row class="lineThreeRight-left">
                <el-row class="lineThreeRight-leftOne">告警百分比</el-row>
                <el-row class="lineThreeRight-leftTwo">20%</el-row>
              </el-row>
              <el-row class="lineThreeRight-right"></el-row>
            </el-row>
          </el-row>

          <el-row class="lineFour">
            <el-row class="lineFourLeft">
              校时
            </el-row>
            <el-row class="lineFourMid">
              内容配置
            </el-row>
            <el-row class="lineFourRight">
              预览
            </el-row>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="pagination">
        <el-pagination class="paginationItem"
                       background
                       layout="prev, pager, next,jumper"
                       :total="10">
        </el-pagination>
      </el-row>
    </el-row>

  </el-row>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
  },
  data () {
    //这里存放数据
    return {
      //搜索框绑定字段
      searchForm: {
        plateNumber: '',//车牌号
      },
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）0
  mounted () {
  },
  //方法集合
  methods: {
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  width: 100%;
  height: 100%;
  .topSelectTwoLine {
    @include background('blockBg');
    height: $topSelectHeight;
    width: 100%;
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 101px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    height: 88%;
    @include background('blockBg');
    margin-top: 16px;
    position: relative;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      padding-left: 1%;
      .topButton {
        display: flex;
        float: right;
      }
    }
    .tableContent {
      width: 100%;
      height: 82%;
      margin-top: 1%;
      display: flex;
      flex-wrap: wrap;
      .box {
        width: 19%;
        height: 45%;
        margin-left: 1%;
        margin-top: 1%;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        @include background('thirdScreenBoxBack');
        .lineOne {
          width: 100%;
          height: 25%;
          display: flex;
          .lineOneLeft {
            width: 20%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .lineOneLeftItem {
              width: 20px;
              height: 20px;
              background: #ffffff;
              border: 2px solid #707070;
              border-radius: 50%;
            }
          }
          .lineOneRight {
            width: 80%;
            height: 100%;
            .lineOneRight-one {
              width: 100%;
              height: 50%;
              display: flex;
              align-items: center;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 30px;
              @include font-color('thirdScreenBoxTitleNumber');
            }
            .lineOneRight-two {
              width: 100%;
              height: 50%;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              line-height: 20px;
              @include font-color('thirdScreenBoxTitle');
            }
          }
        }
        .lineTwo {
          width: 100%;
          height: 30%;
          display: flex;
          .lineTwoLeft {
            width: 47%;
            height: 80%;
            margin-left: 2%;
            background: linear-gradient(
              90deg,
              #4aaefe 0%,
              #467bf9 100%,
              #22687b 100%
            );
            border-radius: 4px;
            .lineTwoLeft-left {
              width: 60%;
              height: 100%;
              padding-left: 5%;
              .lineTwoLeft-leftOne {
                width: 100%;
                height: 50%;
                font-size: 16px;
                line-height: 40px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
              }
              .lineTwoLeft-leftTwo {
                width: 100%;
                height: 50%;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 30px;
                color: #ffffff;
              }
            }
            .lineTwoLeft-right {
            }
          }
          .lineTwoRight {
            width: 47%;
            height: 80%;
            margin-left: 2%;
            background: linear-gradient(
              272deg,
              #0171b3 0%,
              #2491cf 100%,
              #22687b 100%
            );
            border-radius: 4px;
            .lineTwoRight-left {
              width: 60%;
              height: 100%;
              padding-left: 5%;
              .lineTwoRight-leftOne {
                width: 100%;
                height: 50%;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 40px;
                color: #ffffff;
              }
              .lineTwoRight-leftTwo {
                width: 100%;
                height: 50%;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 30px;
                color: #ffffff;
              }
            }
            .lineTwoRight-right {
            }
          }
        }
        .lineThree {
          width: 100%;
          height: 30%;
          display: flex;
          .lineThreeLeft {
            width: 47%;
            height: 80%;
            margin-left: 2%;
            background: linear-gradient(92deg, #22c491 0%, #049165 100%);
            border-radius: 4px;
            .lineThreeLeft-left {
              width: 60%;
              height: 100%;
              padding-left: 5%;
              .lineThreeLeft-leftOne {
                width: 100%;
                height: 50%;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 40px;
                color: #ffffff;
              }
              .lineThreeLeft-leftTwo {
                width: 100%;
                height: 50%;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 30px;
                color: #ffffff;
              }
            }
            .lineThreeLeft-right {
            }
          }
          .lineThreeRight {
            width: 47%;
            height: 80%;
            margin-left: 2%;
            background: linear-gradient(90deg, #f7bb19 0%, #f47430 100%);
            border-radius: 4px;
            .lineThreeRight-left {
              width: 60%;
              height: 100%;
              padding-left: 5%;
              .lineThreeRight-leftOne {
                width: 100%;
                height: 50%;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 40px;
                color: #ffffff;
              }
              .lineThreeRight-leftTwo {
                width: 100%;
                height: 50%;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 30px;
                color: #ffffff;
              }
            }
            .lineThreeRight-right {
            }
          }
        }
        .lineFour {
          width: 100%;
          height: 15%;
          display: flex;
          justify-content: space-around;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 21px;
          color: #ffffff;
          .lineFourLeft {
            width: 30%;
            height: 80%;
            background: linear-gradient(
              90deg,
              #4aaefe 0%,
              #467bf9 100%,
              #22687b 100%
            );
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .lineFourMid {
            width: 30%;
            height: 80%;
            background: linear-gradient(92deg, #22c491 0%, #0a9f70 100%);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .lineFourRight {
            width: 30%;
            height: 80%;
            background: linear-gradient(90deg, #f7bb19 0%, #f47430 100%);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .pagination {
      width: 100%;
      height: 5%;
      position: absolute;
      bottom: 0;
      .paginationItem {
        float: right;
        right: 0;
        bottom: 0;
      }
    }
  }
}
</style>
